import { html, css } from "lit";
import { TWStyles } from './../../tw.js';

import { PwaPage } from '../components/pwa-page.js';

import { routes, ancres, contact_email } from '../../pwa-config.json';

export class PwaMentions extends PwaPage {
    static styles = [css``, TWStyles];

    ancres = ancres["mentions-legales"];

    content = html`
        <div class="h-36 w-full bg-pwa bg-a bg-cover bg-right" aria-hidden></div>
        <section class="container flex flex-col gap-6 py-24">
            <h1 class="text-4xl uppercase text-pwa font-muli font-black">Mentions légales</h1>
            <div .id=${this.ancres.conditions.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">1. Conditions générales d’utilisation</h1>
                <p class="text-xl text-justify">L’utilisation du site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière. Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> est mis à jour régulièrement. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
            </div>
            <div .id=${this.ancres.propriete.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">2. Propriété intellectuelle</h1>
                <p class="text-xl text-justify">Ce site appartient et est exploité par PWA. La présentation et chacun des éléments, y compris les marques, logos et noms de domaine, apparaissant sur le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a>, sont protégés par les lois en vigueur sur la propriété intellectuelle, et appartiennent à PWA ou à ses filiales, ou font l’objet d’une autorisation d’utilisation. Aucun élément composant le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> ne peut être copié, reproduit, modifié, réédité, chargé, dénaturé, transmis ou distribué de quelque manière que ce soit, sous quelque support que ce soit, de façon partielle ou intégrale, sans l’autorisation écrite et préalable de PWA à l’exception d’une stricte utilisation pour les besoins de la presse et sous réserve du respect des droits de propriété intellectuelle et de tout autre droit de propriété dont il est fait mention. Seule la copie à usage privé est autorisée pour votre usage personnel, privé et non-commercial, sur votre ordinateur personnel. Toute utilisation autorisée des éléments composant ou figurant dans le site doit se faire sans dénaturation, modification ou altération de quelque manière que ce soit. PWA ou ses filiales se réservent le droit de poursuivre tout acte de contrefaçon de ses droits de propriété intellectuelle.</p>
            </div>
            <div .id=${this.ancres.services.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">3. Description des services fournis</h1>
                <p class="text-xl text-justify">Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> a pour objet de fournir une information concernant les activités de la société PWA et une description des fonctionnalités du progiciel Atlas à des fins commerciales. PWA s’efforce d’assurer au mieux de ses possibilités, l’exactitude et la mise à jour des informations diffusées sur le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a>, dont elle se réserve le droit de corriger, à tout moment et sans préavis, le contenu.</p>
            </div>
            <div .id=${this.ancres.responsabilite.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">4. Limitation de responsabilité</h1>
                <p class="text-xl text-justify">Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> contient un certain nombre de liens hypertextes vers d’autres sites. Cependant, PWA n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait. Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> utilise la technologie Lit/JavaScript. Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site.</p>
            </div>
            <div .id=${this.ancres.donnees.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">5. Utilisation des données personnelles</h1>
                <p class="text-xl text-justify">PWA ne divulguera pas à des tiers les données personnelles vous concernant que vous pourriez lui communiquer par le moyen de la messagerie électronique. Elles ne seront utilisées que dans le but de vous répondre le plus efficacement possible. Conformément à la "Loi n°2004-801 du 6 août 2004, Loi relative à la protection des personnes physiques à l’égard des traitements de données personnelles et modifiant la loi n°78-17 du 06 janvier 1978 relative à l’informatique, aux fichiers et aux libertés", vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données personnelles qui vous concernent. Pour cela, il suffit d’en faire la demande : -En ligne : dpo@pwa-assurance.fr -Par voie postale : PWA SAS, 13 rue Gilibert 69002 Lyon. Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> n’est pas déclaré à la CNIL car il ne recueille pas d’informations personnelles.</p>
            </div>
            <div .id=${this.ancres.cookies.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">6. Utilisation de cookies</h1>
                <p class="text-xl text-justify">Le cookie se définit comme un fichier enregistré par l’outil de navigation de la machine dans un espace de stockage dédié. Il est stocké au cours de la consultation d’un site web. Son enregistrement dans le disque dur ne donne aucunement lieu à la divulgation d’informations personnelles concernant l’internaute. En continuant à naviguer sur le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a>, vous agréez à l’utilisation de cookies techniques ou de mesure d’audience. Vous pouvez cependant configurer votre navigateur pour refuser l’installation de cookies.</p>
            </div>
            <div .id=${this.ancres.droit.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">7. Droit applicable et attribution de juridiction </h1>
                <p class="text-xl text-justify">Le site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> et son contenu sont régis par le Droit Français, et tout litige éventuel s’y rapportant sera soumis à la compétence des tribunaux français.</p>
            </div>
            <div .id=${this.ancres.mentions.id} class="flex flex-col gap-3">
                <h1 class="text-3xl uppercase text-pwa font-calibri font-black">8. Mentions légales</h1>
                <p class="text-xl text-justify">En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site <a href="${routes.pwa.path}" class="text-pwa font-bold">www.pwa-assurance.fr</a> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :Editeur du site : PWA SAS –13 rue Gilibert 69002 Lyon France ; email : <a href="mailto:${contact_email}" class="text-pwa font-bold">contact@pwa-assurance.fr</a> Directeur de Publication et Responsable de la Rédaction : Monsieur Nicolas WINTER Hébergement du site : OVH - 19 Place Françoise Dorin, 75017 Paris. PWA est une société par actions simplifiée au capital social de 67 270 euros, inscrite au R.C.S. de Lyon sous le numéro 417 520 343.</p>
            </div>
        </section>
    `;
}
customElements.define('pwa-mentions', PwaMentions);