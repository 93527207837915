import { LitElement, html, css } from "lit";
import { TWStyles } from './../../tw.js';

import './pwa-button.js';

import { contact_email } from '../../pwa-config.json';

export class PwaButtonContact extends LitElement {
    static properties = {
        inverted: { type: Boolean }
    };

    static styles = [css``, TWStyles];

    constructor() {
        super();
    }

    render() {
        return html`
        <pwa-button href="mailto:${contact_email}" .inverted=${this.inverted}>Nous contacter</pwa-button>
        `;
    }
}
customElements.define('pwa-button-contact', PwaButtonContact);