import { LitElement, html, css } from "lit";
import { TWStyles } from './../../tw.js';

const imageUrl = new URL('../../assets/pwa/atlas-icon.svg', import.meta.url);
const imageUrlWhite = new URL('../../assets/pwa/atlas-white.webp', import.meta.url);

export class AtlasIcon extends LitElement {
    static styles = [css``, TWStyles];

    static properties = {
        white: { type: Boolean }
    };

    render() {
        return html`
            <img .src=${ this.white ? imageUrlWhite : imageUrl } alt="Atlas Logo">
        `;
    }
}
customElements.define('atlas-icon', AtlasIcon);
  