import { LitElement, html, css} from "lit";
import { TWStyles } from './../../tw.js';

export class PwaButton extends LitElement {
    static styles = [css``, TWStyles];

    static properties = {
        href: { type: String },
        inverted: { type: Boolean }
    };

    constructor() {
        super();
    }

    render() {
        return this.href ? html`
            <a href="${this.href}" class="transition uppercase w-full md:w-52 flex justify-center items-center h-10 font-muli font-black border-2 ${this.inverted ? 'text-white border-white hover:text-pwa hover:bg-white' : 'text-pwa border-pwa hover:text-white hover:bg-pwa'}"><slot></a>
        ` : html`
            <button class="transition uppercase w-full md:w-52 flex justify-center items-center h-10 font-muli font-black border-2 ${this.inverted ? 'text-white border-white hover:text-pwa hover:bg-white' : 'text-pwa border-pwa hover:text-white hover:bg-pwa'}"><slot></button>
        `;
    }
}
customElements.define('pwa-button', PwaButton);