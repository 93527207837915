import { html, css } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/atlas-logo.js';
import '../../components/pwa-button.js';
import tailwindConfig from "../../../tailwind.config.js";

import { routes } from '../../../pwa-config.json';

export class Atlas extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    render() {
        return html`
            <section class="bg-pwa-light py-24 text-primar ">
                <div class="animate-fade-up waiting-for-animation flex flex-col gap-12">
                    <atlas-logo backgroundColor="${tailwindConfig.theme.extend.colors.pwa.light}" class="mx-auto w-full md:w-1/3"></atlas-logo>
                    <div class="container flex flex-col gap-12 justify-center">
                        <pwa-button class="md:mx-auto" .href=${routes.atlas.path}>En savoir plus</pwa-button>
                    </div>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-about-atlas', Atlas);