import { html, css, unsafeCSS } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/pwa-button.js';

import { routes } from '../../../pwa-config.json';

export class Hero extends PwaAnimatedElement {
    static styles = [css`
        .bg-about-hero {
            background-image: url(${unsafeCSS(new URL("../../../assets/covers/about-hero.webp", import.meta.url))});
        }
    `, TWStyles];

    render() {
        return html`
            <section class="bg-about-hero bg-cover bg-center">
                <div class="bg-gradient-to-r from-[#0008] to-[#0008]">
                    <div class="bg-gradient-to-br from-pwa  via-pwa via-30% to-pwa-transparent text-white">
                        <div class="container py-32 flex flex-col gap-12 animate-fade-right waiting-for-animation">
                            <div class="flex flex-col gap-8">
                                <div>
                                    <h1 class="font-muli font-black text-6xl upper tracking-wide">PWA</h1>
                                    <h2 class="font-calibri text-2xl font-light uppercase tracking-wider">Editeur de progiciels assurantiels</h2>
                                </div>
                                <p class="font-calibri text-2xl tracking-wide font-light whitespace-pre-line md:w-2/3 lg:w-1/2 xl:w-1/3">Parce que nos clients, gestionnaires de contrats, évoluent sur un marché très concurrentiel, ils doivent pouvoir se distinguer par la qualité de leurs offres et l’excellence de leur gestion clients.</p>
                            </div>
                            <pwa-button href="${routes.atlas.path}" inverted>Atlas</pwa-button>
                        </div>
                    </div>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-about-hero', Hero);