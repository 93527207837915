import { LitElement, html, css } from "lit";
import { TWStyles } from './../../tw.js';

import '../components/pwa-header.js';
import '../components/pwa-footer.js';

export class PwaPage extends LitElement {
    static styles = [css``, TWStyles];

    constructor() {
        super();

        this.addEventListener('navigate', (event) => {
            console.log(event.detail, this.shadowRoot);
            const element = this.shadowRoot.querySelector("#" + event.detail);
            console.log(element);
            if (element) {
                element.scrollIntoView({ behavior: "smooth"});
            }
        });
    }

    ancres = {}

    content = html``;

    render() {
        return html`
            <pwa-header></pwa-header>
            <main class="bg-white">
                ${this.content}
            </main>
            <pwa-footer .ancres=${this.ancres}></pwa-footer>
        `;
    }
}