import { LitElement, html, css, nothing } from "lit";
import { TWStyles } from './../../tw.js';

import '../components/pwa-header.js';

import '../components/pwa-button.js';

import { routes } from '../../pwa-config.json';

export class PwaNotFound extends LitElement {
    static styles = [css``, TWStyles];

    render() {
        return html`
            <div class="flex flex-col h-screen">
                <pwa-header></pwa-header>
                <main class="flex-1 bg-gradient-to-br from-pwa to-pwa-secondary text-white">
                    <section class="h-full bg-a bg-no-repeat bg-right-bottom bg-contain ">
                        <div class="container flex h-full flex-col justify-center items-center py-16 gap-12">
                            <div class="flex flex-col justify-start">
                                <h1 class="text-9xl font-muli font-black">404</h1>
                                <h2 class="text-4xl font-muli font-light">Désolé, page<br>introuvable</h2>
                            </div>
                            <div class="flex flex-col gap-5 justify-center">
                                <p class="text-2xl font-calibri font-light text-center">La page que vous avez demandée est introuvable</p>
                                <div class="flex flex-col md:flex-row w-full justify-center items-stretch md:items-center gap-3">
                                    ${Object.values(routes).map(route => route.hidden ? nothing : html`
                                        <pwa-button href="${route.path}" inverted>${route.label}</pwa-button>
                                    `)}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        `;
    }
}
customElements.define('pwa-not-found', PwaNotFound);