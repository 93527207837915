import { html, css, unsafeCSS } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

export class Services extends PwaAnimatedElement {
    static styles = [css`
        .bg-stats {
            background-image: url(${unsafeCSS(new URL("../../../assets/covers/stats.webp", import.meta.url))});
        }
        .bg-flux {
            background-image: url(${unsafeCSS(new URL("../../../assets/covers/flux.webp", import.meta.url))});
        }
        .bg-integration {
            background-image: url(${unsafeCSS(new URL("../../../assets/covers/integration.webp", import.meta.url))});
        }
        .bg-extranet {
            background-image: url(${unsafeCSS(new URL("../../../assets/covers/extranet.webp", import.meta.url))});
        }
    `, TWStyles];

    infos = [
        {
            titre: "REPORTING & DECISIONNEL",
            text: "Vos données de gestion disponibles et accessibles\nMise à disposition d'extractions pour les besoins de gestion",
            reverse: false,
            bgClass: "bg-stats",
            delayClass: ""
        },
        {
            titre: "FLUX EXTERNES",
            text: "Une multitude de flux entrants et sortants\nNOEMIE, PASRAU, DSN mensuelle et évènementielle",
            reverse: true,
            bgClass: `bg-flux`,
            delayClass: "animate-delay-200"
        },
        {
            titre: "INTEGRATION",
            text: "Mise à disposition d'un catalogue d'API\nExport des données de gestion dans le SI comptable",
            reverse: false,
            bgClass: "bg-integration",
            delayClass: "animate-delay-400"
        },
        {
            titre: "EXTRANET",
            text: "Extranet assurés, Extranet Entreprise,\nExtranet Délégataire, Outils d'avant vente, Tarificateurs en ligne, ...",
            reverse: true,
            bgClass: "bg-extranet",
            delayClass: "animate-delay-600"
        },
    ];

    render() {
        return html`
            <section class="bg-zinc-600 h-fit  text-primary">
            ${this.infos.map((item) =>
                html` 
                    <div class="${item.bgClass} bg-cover bg-center">
                        <div class="bg-gradient-to-r h-fit from-[#0008] to-[#0008]">
                            <div class="${item.reverse ? 'bg-gradient-to-bl md:bg-gradient-to-l' : 'bg-gradient-to-br md:bg-gradient-to-r'} h-fit from-pwa to-pwa-transparent text-white">
                                <div class="container flex h-fit flex-col p-14  justify-center ${item.reverse ? 'items-end' : 'items-start'} gap-4 ${item.reverse ? 'animate-fade-right' : 'animate-fade-left'} waiting-for-animation ${item.delayClass}">
                                        <h1 class="font-muli font-black text-4xl tracking-wide ${item.reverse ? 'text-right' : 'text-left'}">${item.titre}</h1>
                                        <p class="font-calibri text-2xl tracking-wide font-light whitespace-pre-line ${item.reverse ? 'text-right' : 'text-left'}">${item.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                `
            )}
            </section>
        `;
    }
}
customElements.define('pwa-atlas-services', Services);