import { html, css } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/atlas-logo.js';

export class Hero extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    render() {
        return html`
            <section class="bg-gradient-to-br from-pwa  via-pwa via-30% to-pwa-secondary text-white">
                <div class="container py-32 grid grid-cols-3">
                    <div class="col-span-3 md:col-span-1 flex flex-col gap-8 animate-fade-right waiting-for-animation animate-delay-100">
                        <div>
                            <h2 class="font-calibri text-2xl font-light uppercase tracking-wider">Notre solution</h2>
                            <h1 class="font-muli font-black text-6xl uppercase tracking-wide">Atlas</h1>
                        </div>
                    </div>
                    <div class="hidden md:block col-span-2 py-12 animate-fade-right waiting-for-animation">
                        <atlas-logo white></atlas-logo>
                    </div>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-atlas-hero', Hero);