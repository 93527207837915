import { html, css } from "lit";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

export class Clients extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    clients = [
        { name: "Almerys", url: new URL('../../../assets/clients/almerys.webp', import.meta.url) },
        { name: "Pesenti", url: new URL('../../../assets/clients/pesenti.webp', import.meta.url) },
        { name: "Multi-Impact", url: new URL('../../../assets/clients/multi-impact.webp', import.meta.url) },
        { name: "Repam", url: new URL('../../../assets/clients/repam.webp', import.meta.url) },
        { name: "Mutuelle du Rempart", url: new URL('../../../assets/clients/mutuelle-du-rempart.webp', import.meta.url) },
        { name: "Hemos santé", url: new URL('../../../assets/clients/hemos.webp', import.meta.url) },
        // { name: "Asrama", url: new URL('../../../assets/clients/asrama.webp', import.meta.url) },
        { name: "Territoria", url: new URL('../../../assets/clients/territoria.svg', import.meta.url) },
        { name: "Klesia Mut'", url: new URL('../../../assets/clients/klesiamut.svg', import.meta.url) },
        { name: "Mutlog", url: new URL('../../../assets/clients/mutlog.webp', import.meta.url) },
    ];

    firstUpdated() {
        super.firstUpdated();
        new Splide( this.shadowRoot.querySelector('.splide'), {
            arrows : false,
            pagination : false,
            type   : 'loop',
            drag   : 'free',
            autoWidth: true,
            autoScroll: {
              speed: 1,
            },
            gap: '2rem',
        } ).mount( { AutoScroll } );
    }

    render() {
        return html`
            <section class="bg-white py-24 text-black flex flex-col gap-12">
                <h1 class="uppercase font-muli text-center container text-2xl font-black animate-fade-up waiting-for-animation">Ils nous font confiance</h1>
                <div class="splide" aria-label="Nos clients">
                    <div class="splide__track">
                            <ul class="splide__list">
                                ${this.clients.map(client => html`
                                    <li class="splide__slide"><img .src=${client.url} .alt=${client.name} class="h-24" .title=${client.name}></li>
                                `)}
                            </ul>
                    </div>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-about-clients', Clients);