import {LitElement, html, css} from 'lit';
import { Router } from '@vaadin/router';
import { TWStyles } from './../tw.js';

import './pages/pwa-about.js';
import './pages/pwa-atlas.js';
import './pages/pwa-mentions.js';
import './pages/pwa-not-found.js';

import { routes } from '../pwa-config.json';

export class PwaApp extends LitElement {

  firstUpdated() {
    super.firstUpdated();
    const router = new Router(this.shadowRoot.querySelector('#outlet'));
    router.setRoutes([
      ...Object.values(routes),
      { path: '(.*)', component: 'pwa-not-found' },
    ]);
  }

  static styles = [css``, TWStyles];

  constructor() {
    super();
  }

  render() {
    return html`
      <div id="outlet"></div>
    `;
  }
}
customElements.define('pwa-app', PwaApp);
