{
    "contact_email": "contact@pwa-assurance.fr",
    "routes": {
        "pwa": { "path": "/", "component": "pwa-about", "label": "La société", "hidden" : false },
        "atlas": { "path": "/atlas", "component": "pwa-atlas", "label": "Atlas", "hidden" : false },
        "mentions": { "path": "/mentions-legales", "component": "pwa-mentions", "label": "Mentions légales", "hidden" : true }
    },
    "ancres": {
        "pwa": {
            "hero": { "id": "hero", "label": "PWA"},
            "atlas": { "id": "atlas", "label": "ATLAS"},
            "presentation": { "id": "presentation", "label": "Qui sommes-nous ?"},
            "clients": { "id": "clients", "label": "Ils nous font confiance"},
            "chiffres": { "id": "chiffres", "label": "Nos chiffres clés"},
            "equipe": { "id": "equipe", "label": "Vos interlocuteurs"}
        },
        "atlas": {
            "hero": { "id": "hero", "label": "ATLAS"},
            "presentation": { "id": "presentation", "label": "Présentation"},
            "services": { "id": "services", "label": "Services"},
            "fonctionnalites": { "id": "fonctionnalites", "label": "Fonctionnalités"}
        },
        "mentions-legales": {
            "conditions": { "id": "conditions", "label": "Conditions générales d'utilisation"},
            "propriete": { "id": "propriete", "label": "Propriété intellectuelle"},
            "services": { "id": "services", "label": "Description des services fournis"},
            "responsabilite": { "id": "responsabilite", "label": "Limitation de responsabilité"},
            "donnees": { "id": "donnees", "label": "Utilisation des données personnelles"},
            "cookies": { "id": "cookies", "label": "Utilisation de cookies"},
            "droit": { "id": "droit", "label": "Droit applicable et attribution de juridiction"},
            "mentions": { "id": "mentions", "label": "Mentions légales"}
        }
    
    }
}