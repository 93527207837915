import { LitElement, html, css } from "lit";
import { TWStyles } from './../../tw.js';

const imageUrl = new URL('../../assets/pwa/atlas-full.webp', import.meta.url);
const imageUrlWhite = new URL('../../assets/pwa/atlas-full-white.webp', import.meta.url);

export class AtlasLogo extends LitElement {
    static styles = [css``, TWStyles];

    static properties = {
        white: { type: Boolean }
    };

    render() {
        return this.white
        ? html`<img src="${ imageUrlWhite }" alt="Atlas Logo">`
        : html`<img src="${ imageUrl }" alt="Atlas Logo">`;
    }
}
customElements.define('atlas-logo', AtlasLogo);
  