import { html, css } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/pwa-button.js';
import '../../components/atlas-icon.js';

export class Presentation extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    infos = [
        {label: "Création en 1998 par des actuaires et professionnels de l'assurance et de la réassurance", delayClass: "animate-delay-100"},
        {label: "Entreprise indépendante à taille humaine, siège social à Lyon", delayClass: "animate-delay-200"},
        {label: "Expertise en assurance Santé, Prévoyance et Emprunteur", delayClass: "animate-delay-300"},
        {label: "Une connaissance métier depuis plus de 23 ans", delayClass: "animate-delay-400"}
    ]

    render() {
        return html`
        <section class="bg-gradient-to-tl from-pwa  via-pwa via-30% to-pwa-secondary text-white">
            <div class="bg-a bg-no-repeat bg-right-bottom bg-contain">
                <div class="container py-32 flex flex-col gap-12">
                    <div class="grid grid-cols-2 md:grid-cols-3 gap-6">
                        <div class="flex flex-col gap-12 col-span-2">
                            <div>
                                <h2 class="font-calibri text-2xl font-light uppercase tracking-wider animate-fade-left waiting-for-animation">Qui sommes nous ?</h2>
                                <h1 class="font-muli font-black text-6xl uppercase tracking-wide animate-fade-left waiting-for-animation">PWA</h1>
                            </div>
                            <ul class="ps-9 list-image-atlas-md list-outside text-2xl flex flex-col gap-6 font-calibri font-bold">
                                ${this.infos.map((info) => html`
                                    <li><span class="block ps-3 -top-3 relative whitespace-pre-line animate-fade-left waiting-for-animation ${info.delayClass}">${info.label}</span></li>
                                `)}
                            </ul>
                        </div>
                        <div class="col-span-1 animate-fade-left waiting-for-animation hidden md:block">
                            <atlas-icon white></atlas-icon>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        `;
    }
}
customElements.define('pwa-about-presentation', Presentation);