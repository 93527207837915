import { html, css, unsafeCSS } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/pwa-button-contact.js';

export class Contact extends PwaAnimatedElement {
    static styles = [css`
        .bg-contact {
            background-image: url(${unsafeCSS(new URL("../../../assets/covers/contact.webp", import.meta.url))});
        }
    `, TWStyles];

    render() {
        return html`
            <section class="bg-contact bg-cover bg-center py-24 relative">
                <div class="absolute h-full w-3/5 top-0 left-0 py-24 hidden md:block">
                    <div class="bg-white h-full w-full"></div>
                </div>
                <div class=" text-pwa flex flex-col gap-6 container py-24 relative animate-fade-right waiting-for-animation bg-white md:bg-transparent">
                    <h1 class="uppercase font-muli font-black text-3xl">Nous contacter</h1>
                    <div class="flex flex-col">
                        <h2 class="uppercase font-calibri font-black text-xl tracking-wider">Adresses</h2>
                        <span class="font-calibri font-light text-xl">13, Rue Gilibert, 69002 Lyon</span>
                        <span class="font-calibri font-light text-xl">19, Cité industrielle, 75011 Paris</span>
                    </div>
                    <div class="flex flex-col">
                        <h2 class="uppercase font-calibri font-black text-xl tracking-wider">Email</h2>
                        <span class="font-calibri font-light text-xl"><a href="mailto:contact@pwa-assurance.fr">contact@pwa-assurance.fr</a></span>
                    </div>
                    <pwa-button-contact></pwa-button-contact>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-about-contact', Contact);