import { html, css } from "lit";
import { TWStyles } from './../../tw.js';

import { PwaPage } from '../components/pwa-page.js';

import '../fragments/about/hero.js';
import '../fragments/about/atlas.js';
import '../fragments/about/presentation.js';
import '../fragments/about/clients.js';
import '../fragments/about/chiffres.js';
import '../fragments/about/confiance.js';
import '../fragments/about/contact.js';
import '../fragments/about/equipe.js';

import { ancres } from '../../pwa-config.json';

export class PwaAbout extends PwaPage {
    static styles = [css``, TWStyles];

    ancres = ancres.pwa;

    content = html`
        <pwa-about-hero .id=${this.ancres.hero.id}></pwa-about-hero>
        <pwa-about-atlas .id=${this.ancres.atlas.id}></pwa-about-atlas>
        <pwa-about-presentation .id=${this.ancres.presentation.id}></pwa-about-presentation>
        <pwa-about-clients .id=${this.ancres.clients.id}></pwa-about-clients>
        <pwa-about-chiffres .id=${this.ancres.chiffres.id}></pwa-about-chiffres>
        <pwa-about-confiance></pwa-about-confiance>
        <pwa-about-contact></pwa-about-contact>
        <pwa-about-equipe .id=${this.ancres.equipe.id}></pwa-about-equipe>
    `;
}
customElements.define('pwa-about', PwaAbout);