import { html, css } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/pwa-button.js';

import { ancres } from '../../../pwa-config.json';

export class Confiance extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    infos = [
        {label: "100% de rétention client depuis sa création en 1998", img: new URL('../../../assets/icons/groupe.webp', import.meta.url), delayClass: "animate-delay-100"},
        {label: "Solidité financière et indépendante", img: new URL('../../../assets/icons/euros.webp', import.meta.url), delayClass: "animate-delay-200"},
        {label: "100% des équipes en France", img: new URL('../../../assets/icons/france.webp', import.meta.url), delayClass: "animate-delay-300"}
    ]

    navigate() {
        this.dispatchEvent(new CustomEvent('navigate', { detail: ancres.pwa.equipe.id, bubbles: true, composed: true }));
    }

    render() {
        return html`
            <section class="bg-white py-24 text-pwa">
                <div class="container flex flex-col items-center gap-12">
                    <h1 class="uppercase font-muli text-center container text-2xl font-black animate-fade-up waiting-for-animation">Une confiance prouvée et une stabilité financière</h1>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 w-3/5">
                        ${this.infos.map((info) => html`
                            <div class="flex flex-col animate-fade-up waiting-for-animation ${info.delayClass}">
                                <img src=${info.img} alt="illustration" class="w-full aspect-square p-3">
                                <p class="text-xl font-calibri font-bold text-center">${info.label}</p>
                            </div>
                        `)}
                    </div>
                    <pwa-button class="w-full md:w-fit animate-fade-up waiting-for-animation animate-delay-400" @click="${() => this.navigate()}"}>Vos interlocuteurs</pwa-button>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-about-confiance', Confiance);