import { LitElement, css } from "lit";
import { TWStyles } from './../../tw.js';
import { CountUp } from "countup.js";

export class PwaAnimatedElement extends LitElement {
    static styles = [css``, TWStyles];
    
    firstUpdated() {
        this.shadowRoot.querySelectorAll('.waiting-for-animation').forEach((element) => {
            let observer;
            observer = new IntersectionObserver(entries => {
                // Loop over the entries
                entries.forEach(entry => {
                  // If the element is visible
                  if (entry.isIntersecting) {
                    // Add the animation class
                    entry.target.classList.remove('waiting-for-animation');
                    observer.unobserve(entry.target);
                    return;
                  }
                });
            });
            observer.observe(element);
        });
        
        this.shadowRoot.querySelectorAll('.animate-number').forEach((element) => {
          let observer;
          const value = parseInt(element.innerText.replace(/\s/g, ''));

          const countUp = new CountUp(element, value, { duration: 3, separator: ' ' });
          observer = new IntersectionObserver(entries => {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  if (!countUp.error) {
                    countUp.start();
                  } else {
                    console.error(countUp.error);
                  }
                  observer.unobserve(entry.target);
                  return;
                }
              });
          });

          observer.observe(element);
      });
    }
}