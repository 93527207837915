import { html, css } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

export class Chiffres extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    chiffres = [
        { label: "personnes assurées", value: "2 200 000" },
        { label: "contrats individuels", value: "1 200 000" },
        { label: "contrats collectifs", value: "150 000" },
    ];

    render() {
        return html`
            <svg class="w-full h-12 text-pwa-gray -mb-1 relative z-10" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon fill="currentColor" points="0,0 100,100 0,100"/>
                <polygon fill="white" points="0,0 100,0 100,100"/>
            </svg>
            <section class="bg-pwa-gray py-24 text-pwa relative">
                <div class="container flex flex-col gap-12">
                    <h1 class="uppercase font-muli text-center container text-2xl font-black animate-fade-right waiting-for-animation">Une expérience éprouvée au quotidien</h1>
                    <div class="grid gap-y-9 grid-cols-1 md:grid-cols-3">
                        ${this.chiffres.map((chiffre) => html`
                            <div class="flex flex-col items-center">
                                <div class="relative flex flex-col items-center md:items-start ${chiffre.delayClass}">
                                    <span aria-hidden="true" class="text-6xl md:text-5xl lg:text-6xl font-calibri font-black opacity-0 whitespace-nowrap text-nowrap">0 000 000</span>
                                    <span class="absolute text-6xl md:text-5xl lg:text-6xl  font-calibri font-black animate-number whitespace-nowrap waiting-for-animation text-nowrap">${chiffre.value}</span>
                                    <span class="text-2xl md:text-xl lg:text-2xl font-calibri font-black">${chiffre.label}</span>
                                </div>
                            </div>
                        `)}
                    </div>
                </div>
            </section>
            <svg class="w-full h-12 text-pwa-gray -mt-1 relative z-10" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon fill="currentColor" points="0,0 100,0 100,100"/>
                <polygon fill="white" points="0,0 100,100 0,100"/>
            </svg>
        `;
    }
}
customElements.define('pwa-about-chiffres', Chiffres);