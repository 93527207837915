import { LitElement, html, css, nothing } from "lit";
import { TWStyles } from './../../tw.js';

import { routes } from '../../pwa-config.json';

import './pwa-logo.js';

export class PwaHeader extends LitElement {
    static styles = [css``, TWStyles];

    static properties = {
        showMenu: { type: Boolean, reflect: true }
    };

    constructor() {
        super();
        this.showMenu = false;
    }

    toggleMenu() {
        this.showMenu  = !this.showMenu;
        console.log("toggleMenu", this.showMenu);
    }

    render() {
        return html`
        <nav class="bg-white">
        <div class="md:container flex flex-col md:flex-row justify-between items-stretch">
            <!-- TODO: bricolage -->
            <div class="flex flex-row justify-between items-center p-3 md:p-0">
                <a href="/" class="flex justify-center items-center" aria-label="Accueil du site">
                    <pwa-logo class="w-20 h-8 flex justify-center items-center"></pwa-logo>
                </a>
                <div class="md:hidden flex flex-col justify-evenly items-center w-9 h-9 cursor-pointer" @click=${this.toggleMenu}>
                    <div class="w-full h-0 border-t-4 border-pwa"></div>
                    <div class="w-full h-0 border-t-4 border-pwa"></div>
                    <div class="w-full h-0 border-t-4 border-pwa"></div>
                </div>
            </div>
            <div class="pb-3 md:pb-0 ${this.showMenu ? 'block' : 'hidden'} md:block">
                <ul class="flex flex-col md:flex-row">
                    ${Object.values(routes).map(route => route.hidden ? nothing : html` 
                    <li><a href="${route.path}" class="block border-x-4 md:border-x-0 md:border-y-4 border-b-transparent border-r-transparent hover:border-l-pwa-secondary hover:border-t-pwa-secondary ${route.path === window.location.pathname ? 'border-l-pwa border-t-pwa' : 'border-l-transparent border-t-transparent'} py-2 px-4 uppercase text-xl font-black font-muli text-pwa">${route.label}</a></li>
                    `)}
                </ul>
            </div>
        </div>
        </nav>
        `;
    }
}
customElements.define('pwa-header', PwaHeader);
