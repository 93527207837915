import { html, css } from "lit";
import { TWStyles } from './../../../tw.js'

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/linkedin-logo.js';

import tailwindConfig from '../../../tailwind.config.js';

export class Equipe extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    items = [
        { name: "Nicolas Winter", role: "PDG", image: new URL("./../../../assets/equipe/NWI.webp", import.meta.url), link: "https://www.linkedin.com/in/nicolas-winter-2a036bb", delayClass: "animate-delay-100" },
        { name: "Thomas Boulanger", role: "Directeur des opérations et projets", image: new URL("./../../../assets/equipe/TBO.webp", import.meta.url), link: "https://www.linkedin.com/in/thomas-boulanger-028149144", delayClass: "animate-delay-200" },
        { name: "Valérie Hunot", role: "Directrice métier et maîtrise d’ouvrage", image: new URL("./../../../assets/equipe/VHU.webp", import.meta.url), link: "https://www.linkedin.com/in/valeriehunot", delayClass: "animate-delay-300" },
        { name: "Thomas Chanois", role: "Responsable de compte et chef de projet", image: new URL("./../../../assets/equipe/TCH.webp", import.meta.url), link: "https://www.linkedin.com/in/thomas-chanois-0772837b", delayClass: "animate-delay-400" },
        { name: "Jérôme Perraud", role: "Responsable de compte et data manager", image: new URL("./../../../assets/equipe/JPE.webp", import.meta.url), link: "https://www.linkedin.com/in/j%C3%A9r%C3%B4me-perraud-7b0a19b4", delayClass: "animate-delay-500" },
        { name: "Jonathan Breton", role: "Responsable de compte et expert comptabilité", image: new URL("./../../../assets/equipe/JBR.webp", import.meta.url), link: "https://www.linkedin.com/in/jonathan-breton-120907141", delayClass: "animate-delay-600" },
    ]

    render() {
        return html`
            <section class="bg-white py-24">
                <div class="container flex flex-col gap-12">
                    <h1 class="uppercase font-muli text-center container text-2xl font-black text-pwa animate-fade-up waiting-for-animation">Vos interlocuteurs</h1>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-3 md:w-3/4 mx-auto">
                        ${this.items.map((item) => html`
                            <div class="flex flex-col items-center relative animate-fade-up waiting-for-animation ${item.delayClass}">
                                <img src=${item.image} alt=${item.name} class="aspect-square">
                                <div class="opacity-0 hover:opacity-100 absolute transition-all w-full h-full flex flex-col justify-center items-center bg-gradient-to-br from-pwa to-pwa-secondary text-white gap-6 p-9">
                                    <div class="flex flex-col">
                                        <p class="text-xl font-muli font-black text-center uppercase">${item.name}</p>
                                        <p class="text-xl font-calibri font-light text-center">${item.role}</p>
                                    </div>
                                    <linkedin-logo .link=${item.link} color="white" textColor="${tailwindConfig.theme.extend.colors.pwa.DEFAULT}" size="36"></linkedin-logo>
                                </div>
                            </div>
                        `)}
                    </div>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-about-equipe', Equipe);