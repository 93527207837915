import { LitElement, html, css } from "lit";
import { TWStyles } from './../../tw.js';

const imageUrl = new URL('../../assets/pwa/pwa-logo-sm.png', import.meta.url);
const imageUrlFull = new URL('../../assets/pwa/pwa-full.svg', import.meta.url);
const imageUrlWhite = new URL('../../assets/pwa/pwa-white.webp', import.meta.url);

export class PwaLogo extends LitElement {
    static styles = [
        css`
            .cls-1 {
                fill: url(#Dégradé_sans_nom_342);
            }

            .cls-2 {
                letter-spacing: -.01em;
            }

            .cls-3 {
                fill: #00435d;
                font-family: Muli-Black, 'Muli Black';
                font-size: 432.18px;
            }

            .cls-4 {
                letter-spacing: 0em;
            }

            .cls-5 {
                letter-spacing: -.02em;
            }

            .cls-6 {
                fill: #05668d;
            }

            .cls-7 {
                letter-spacing: -.02em;
            }

            .cls-8 {
                fill: url(#Dégradé_sans_nom_50);
            }

            .cls-9 {
                fill: url(#Dégradé_sans_nom_3);
            }

            .cls-10 {
                fill: url(#Dégradé_sans_nom_4);
            }

            .cls-11 {
                letter-spacing: 0em;
            }

            .cls-12 {
                fill: url(#linear-gradient);
            }

            .cls-13 {
                fill: url(#Dégradé_sans_nom_19);
            }

            .cls-14 {
                letter-spacing: 0em;
            }

            .cls-16 {
                letter-spacing: -.01em;
            }

            .cls-17 {
                fill: url(#Dégradé_sans_nom_185);
            }

            .cls-18 {
                fill: url(#Dégradé_sans_nom_250);
            }

            .cls-19 {
                letter-spacing: -.02em;
            }
        `, TWStyles];

    static properties = {
        full: { type: Boolean },
        white: { type: Boolean }
    };

    render() {
        return html`
            <img .src=${ this.white ? imageUrlWhite : (this.full ? imageUrlFull : imageUrl) } alt="PWA Logo">
        `;
    }
}
customElements.define('pwa-logo', PwaLogo);