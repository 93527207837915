import { html, css } from "lit";
import { TWStyles } from './../../tw.js';

import { PwaPage } from '../components/pwa-page.js';

import '../fragments/atlas/hero.js';
import '../fragments/atlas/atlas.js';
import '../fragments/atlas/services.js';
import '../fragments/atlas/fonctionnalites.js';

import { ancres } from '../../pwa-config.json';

export class PwaAtlas extends PwaPage {
    static styles = [css``, TWStyles];

    ancres = ancres.atlas;

    content = html`
        <pwa-atlas-hero .id=${this.ancres.hero.id}></pwa-atlas-hero>
        <pwa-atlas-presentation .id=${this.ancres.presentation.id}></pwa-atlas-presentation>
        <pwa-atlas-services .id=${this.ancres.services.id}></pwa-atlas-services>
        <pwa-atlas-fonctionnalites .id=${this.ancres.fonctionnalites.id}/><pwa-atlas-fonctionnalites>
    `;
}

customElements.define('pwa-atlas', PwaAtlas);
