import { html, css } from "lit";
import { TWStyles } from './../../../tw.js';

import { PwaAnimatedElement } from '../../components/pwa-animated-element.js';

import '../../components/atlas-logo.js';

export class Fonctionnalites extends PwaAnimatedElement {
    static styles = [css``, TWStyles];

    infos = [
        {
            titre: "TARIFICATION",
            text: "Paramétrage, algorithmes de calcul, devis, plateforme d’avant vente",
            icon: new URL('../../../assets/icons/tarification.svg', import.meta.url),
            delayClass: ""
        },
        {
            titre: "CONTRATS / COTISATIONS",
            text: "Paramétrage de produits, gestion du collectif et de l'individuel",
            icon: new URL('../../../assets/icons/contrats-cotisations.svg', import.meta.url),
            delayClass: "animate-delay-100"

        },
        {
            titre: "COMMISSIONNEMENT",
            text: "Gestion de réseaux d’apporteurs, courtiers grossiste, calculs d’escompte",
            icon: new URL('../../../assets/icons/commissionnement.svg', import.meta.url),
            delayClass: "animate-delay-200"

        },
        {
            titre: "SINISTRES",
            text: "Valorisation, compteurs complexes, justificatifs, mise en paiement",
            icon: new URL('../../../assets/icons/sinistres.svg', import.meta.url),
            delayClass: "animate-delay-300"
        },
        {
            titre: "COMPTABILITÉ",
            text: "Comptes de tiers, tracabilité et export natif vers les outils comptables",
            icon: new URL('../../../assets/icons/comptabilite.svg', import.meta.url),
            delayClass: "animate-delay-400"

        },
        {
            titre: "TRÉSORERIE / PAIEMENT",
            text: "Gestion SEPA, ventilation de trésorerie, chaine de validation de paiement",
            icon: new URL('../../../assets/icons/tresorerie-paiement.svg', import.meta.url),
            delayClass: "animate-delay-500"
        },
    ];

    render() {
        return html`
            <section class="bg-white h-fit text-primary">
                <div class="bg-a bg-no-repeat bg-cover bg-right-bottom h-full py-20">
                    <div class="container mx-auto grid gap-6 items-center justify-center  lg:grid-cols-2 xl:grid-cols-3  ">
                    ${this.infos.map((item) =>
                        html`
                        <div class="h-full flex flex-col items-start justify-center p-6 gap-2 border-1 border-pwa border-opacity-40 animate-fade-up waiting-for-animation ${item.delayClass}">
                            <img width="85" height="85" class="pb-2 aspect-square object-contain object-left" src=${item.icon} alt="${item.titre}">
                            <h1 class="font-muli font-black text-pwa text-2xl tracking-wide">${item.titre}</h1> 
                            <p class="font-calibri text-xl text-pwa tracking-wide font-light whitespace-pre-line">${item.text}</p>
                        </div>
                        `
                    )}
                    </div>
                </div>
            </section>
        `;
    }
}
customElements.define('pwa-atlas-fonctionnalites', Fonctionnalites);