import { LitElement, html, css } from "lit";
import { TWStyles } from './../../tw.js';

import './pwa-button-contact.js';
import './pwa-logo.js';
import './linkedin-logo.js';

import tailwindConfig from '../../tailwind.config.js';
import { routes } from '../../pwa-config.json';

export class PwaFooter extends LitElement {
    static styles = [css``, TWStyles];

    static properties = {
        ancres: { type: Object }
    };

    constructor() {
        super();
        this.ancres = {};
    }

    navigate(id) {
        this.dispatchEvent(new CustomEvent('navigate', { detail: id, bubbles: true, composed: true }));
    }

    render() {
        return html`
        <footer class="h-fit">
            <div class="bg-gradient-to-br from-pwa to-pwa-secondary text-white flex flex-col md:flex-row">
                <div class="basis-2/3 p-6 flex flex-col md:flex-row">
                    <div class="basis-1/2 px-12 py-6 md:py-12 flex justify-center items-center">
                        <pwa-logo full white></pwa-logo>
                    </div>
                    <div class="basis-1/2 flex items-center justify-center md:justify-start">
                        <ul class="uppercase font-calibri font-light tracking-wider flex flex-col gap-1">
                        ${Object.keys(this.ancres).map((key) =>
                            html`<li @click="${() => this.navigate(this.ancres[key].id)}" class="cursor-pointer hover:underline">${this.ancres[key].label}</li>`
                        )}
                        </ul>
                    </div>
                </div>
                <div class="basis-1/3 bg-pwa-gray text-pwa p-6 flex justify-center items-center">
                    <div class="flex flex-col justify-start items-start gap-3">
                        <pwa-button-contact class="mb-2 w-full"></pwa-button-contact>
                        <a class="uppercase font-calibri font-light" href="${routes.mentions.path}">Mentions légales</a>
                        <div class="flex flex-col gap-1">
                            <p class="uppercase font-calibri font-black text-3xl">Suivez-nous</p>
                            <linkedin-logo color="${tailwindConfig.theme.extend.colors.pwa.DEFAULT}" textColor="${tailwindConfig.theme.extend.colors.pwa.gray}" size="36"></linkedin-logo>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-center bg-white text-pwa font-muli font-black">&copy; - Copyright PWA Tous droits réservés</p>
        </footer>
        `;
    }
}
customElements.define('pwa-footer', PwaFooter);
